<template>
  <div class="app-container">
    <div class="card cash-top">
      <div class="main">
        <div class="tit">可提现(元)</div>
        <div class="money">
          {{ (dataList.balance - dataList.take_balance) / 100 }}
        </div>
        <div class="history">
          <span class="txt"
            >冻结金额：￥<strong>
              {{ dataList.take_balance / 100 }}</strong
            ></span
          ><span class="link" @click="$router.push({ name: 'CashRecord' })"
            >收支记录</span
          >
        </div>
      </div>
      <van-button type="info" size="small" @click="toGetCash"
        >去提现</van-button
      >
    </div>

    <!-- <div class="card cash-trend">
      <div class="head">
        <div class="main">
          <div class="tit">每日拥金</div>
          <div class="sub-tit">近7天，每日拥金合计为 45.00 元</div>
        </div>
      </div>
      <div class="chat"></div>
    </div> -->

    <div class="card cash-record" style="display: none">
      <div class="head">
        <div class="main">
          <div class="tit">拥金记录</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Dialog } from 'vant'
import Cookies from 'js-cookie'
export default {
  data () {
    return {
      dataList: {}
    }
  },
  mounted () {
    this.getDataList()
  },
  methods: {
    toGetCash () {
      if (Cookies.get('client_type') === 2) {
        this.$router.push({ name: 'CashGet' })
      } else {
        Dialog.confirm({
          title: '标题',
          message: '申请成为销售代表提取您的拥金，现在就去申请？',
        })
          .then(() => {
            this.$router.push({ name: 'Register' })
          })
          .catch(() => {
            // on cancel
          })
      }

    },
    getDataList () {
      this.$axios.post('/wxc/client/detail').then(res => {
        console.log(res)
        this.dataList = res.data

      })
    }
  }
}
</script>
<style lang="scss" scoped>
.card {
  background: #fff;
  padding: 10px 12px;
  margin-bottom: 15px;
}
.cash-top {
  display: flex;
  align-items: center;
  .main {
    flex: 1;
    .money {
      font-weight: 700;
      font-size: 26px;
      padding: 5px 0 15px 0;
    }
    .history {
      font-size: 12px;
      .txt {
        color: rgba(0, 0, 0, 0.5);
      }
      .link {
        color: #1989fa;
        margin-left: 20px;
      }
    }
  }
}
.cash-trend {
  .head {
    .main {
      .tit {
        font-size: 18px;
        font-weight: 600;
      }
      .sub-tit {
        font-size: 12px;
        color: rgba(0, 0, 0, 0.5);
        padding-top: 5px;
      }
    }
  }
  .chat {
    height: 200px;
  }
}

.cash-record {
  .head {
    .main {
      .tit {
        font-size: 18px;
        font-weight: 600;
      }
    }
  }
}
</style>